@import "./variables.scss";

.nav__container {
  @include flex($dir: column);
}
.nav__container,
.nav__wrapper {
  width: 100%;
  max-width: 1200px;
}
.nav__wrapper {
  @include flex();
  justify-content: space-between;
  padding: 0.9rem 3%;
}

// Logo
.nav__logo {
  @include flexC();
  width: 140px;
  fill: $base;
  text-decoration: none;
  overflow: hidden;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1.05);
  }
}

// Socials
.nav__socials {
  display: none;
}
.nav__social {
  display: none;
}

// Buttons
.nav__btns {
  @include flex();
  gap: 2vw;
  padding: 0.2rem 0.8rem 0.2rem 0;
  flex: 1;
  justify-content: flex-end;
}
.nav__btn {
  font-size: 1.25rem;
  padding: 0.8rem;
  font-weight: 600;
  @include flexC();
  border-radius: 7.5px;
  text-transform: capitalize;
  color: $gray;
  &:first-child:hover {
    .nav__chevron {
      transform: translateX(8%);
    }
  }
}
.nav__btn:last-child {
  background: $base;
  color: $white;
  &:hover {
    background: $baseD;
  }
}

// Icon
.nav__chevron {
  width: 1rem;
  height: inherit;
  margin-left: 0.2rem;
  fill: $gray;
}

// Responsive
@media (min-width: 480px) {
  .nav__wrapper {
    padding: 1.5rem 2%;
  }
  .nav__logo {
    width: 170px;
  }
  .nav__btn {
    font-size: 2.8vw;
    &:last-child {
      padding: 0.8rem;
    }
  }
}

@media (min-width: 769px) {
  .nav__wrapper {
    padding: 1.5rem 4% 1.5rem;
  }
  .nav__logo {
  }
  .nav__btns {
    margin-right: 2rem;
    gap: 2vw;
  }
  .nav__btn {
    font-size: 1.5rem;
  }
  .nav__chevron {
    width: 20px;
    margin-left: 0.8rem;
  }
  .nav__socials {
    @include flexC();
    gap: 1vw;
    margin-right: 1rem;
  }
  .nav__social {
    @include flexC();
    overflow: hidden;
    width: 26px;
    height: 26px;
    fill: $base;
  }
  .nav__social:first-child {
    width: 30px;
  }
}
@media (min-width: 1024px) {
  .nav__wrapper {
    width: 90%;
    padding: 1.5rem 0;
  }
  .nav__btn {
    font-size: 1.6rem;
  }
}
@media (min-width: 1200px) {
  .nav__wrapper {
    padding: 2.7rem 0;
    width: 95%;
  }
  .nav__logo {
    width: 195px;
  }
  .nav__btns {
    padding: 0;
    margin-right: 6vw;
  }
  .nav__btn {
    font-weight: 600;
    font-size: 1.8rem;
  }
  .nav__btn:last-child {
    padding: 1rem;
  }
}
@media (min-width: 1350px) {
  .nav__wrapper {
    width: 100%;
  }
}
