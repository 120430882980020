@import './variables.scss';

.match_{
    &container{
        width: 100%;
        height: 100%;
        background: $lGray;
        @include flexCol();
        padding: 0.5rem 0.5rem 0.5rem;
        overflow: hidden;
        border-radius: 2px;
        overflow: hidden;
    }
    &topContainer{
        @include flex();
        width: 90%;
        height: 80%;
        overflow: hidden
    }
    &left{
        width: 40%;
        @include flexCenR();
        overflow: hidden

    }
    &logoContainer{
        width: 60px;
        height: 60px;
        @include flexCenR();
        overflow: hidden;
    }
    &right{
        width: 60%;
        @include flexCol();
        align-items: center;
        justify-content: space-between;
        text-align: center;
        height: 80%;
        overflow: hidden
    }
    &hdr{
        font-size: 1.7rem;
        font-weight: 600;
        text-transform: capitalize;
        margin: auto;
        overflow: hidden;
    }
    &text{
        font-size: 1rem;
        overflow: hidden;

    }
    &bottomContainer{
        width: 100%;
        @include flexCenR();
        padding: 0.5rem;
        overflow: hidden;
    }
    &button{
        padding: 0.3rem 0.3rem;
        overflow: hidden;
        font-size: 1.1rem;
        width: 60%;
        font-weight: 600;
        text-transform: capitalize;
        color: $white;
        @include flexCenR();
        background: $base;
        border: 1px solid transparent;
        border-radius: 3px;
        margin: 0.5rem;
        cursor: pointer;
        transition: all 0.3s;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
        transition: all 0.15s ease-in-out;
        &:hover{
            color: $base;
            background: transparent;
            transform: scale(1.2);
            box-shadow: none;
        }
    }
    @media(min-width: 480px){
        &hdr{
            font-size: 1.8rem;
        }
        &text{
            font-size: 1.2rem;
        }
        &button{
            font-size: 1.3rem;
        }
        &logoContainer{
            width: 70px;
            height: 70px;
        }
    }
}