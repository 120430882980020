@import './variables.scss';

.compatible_{
    &container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        padding: 1rem 0.7rem;
        gap: 1rem;
    }
    &left{
        width: 40%;
        @include flexCol();
    }
    &hdr{
        font-size: 0.7rem;
        text-align: center;
        color: $gray;
    }
    &resultTitle{
        color: $base;
        margin: 0.6rem 0 0;
        font-size: 1.2rem;
    }
    &chartContainer{
        margin: 0 0 0.5rem;
        @include flexCenR;
        width: 70px;
        height: 70px;
    }
    &right{
        width: 60%;
        @include flexCol();
        gap: 1rem
    }
    &hdrContainer{
        @include flex();
        width: 90%;
        gap: 0.8rem;
        justify-content: space-between;
    }
    &subHdr{
        font-size: 0.7rem;
        color: $base;
        font-weight: 800;
    }
    &contentContainer{
        @include flexCol;
        width: 100%;
        overflow: visible;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
    &content{
        border: 1px solid $grayBorder;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
        background: $offWhite;
        width: 100%;
        height: 4rem;
        overflow: hidden;
        @include flex();
        // justify-content: space-between;
        padding: 0.2rem 8%;
        border-radius: 8px;
    }
    &contentHdr{
        font-size: 1.1rem;
        font-weight: 600;
        width: fit-content;
        color: $gray;
    }
    &blockchain{
        @include flex();
        justify-content: flex-start;
        width: 60%;
        gap: 0.4rem;
        margin-left: 1rem;
        flex-grow: 1;
    }
    &logoContainer{
        width: 20px;
        width: 20px;
    }
    &blockchainTitle{
        font-size: 1rem;
        color: $base;
    }
    &contentScoreContainer{
        width: 10%;
        @include flexCenR();
    }
    &score{
        font-size: 1.2rem;
        color: $baseD;
    }

    @media(min-width:480px){
        &hdr, &subHdr{
            font-size: 1.2rem;
        }
        &blockchainTitle, &contentHdr{
            font-size: 1.1rem;
        }
        &logoContainer{
            width: 15px;
            margin: 0 0.5rem 0 0;
        }
        &chartContainer{
            width: 90px;
            height: 90px;
            margin: 1rem 0 0.5rem;
        }
    }

    @media(min-width:769px){
        &hdr, &subHdr{
            font-size: 1.8rem;
        }
        &blockchainTitle, &contentHdr{
            font-size: 1.5rem;
        }
        &score{
            font-size: 1.5rem;
        }
        &logoContainer{
            width: 30px;
            margin: 0 0.5rem 0 0;
        }
        &resultTitle{
            font-size: 1.5rem;
        }
        &chartContainer{
            width: 90px;
            height: 90px;
        }
    }
}