@import "./variables.scss";
.questions__container,
.questions__questions-cont,
.questions__success-cont {
  @include flex($dir: column);
}
.questions__success-cont {
  padding: 2rem;
  text-align: center;
  width: 90%;
  max-width: 350px;
  height: 60vh;
  gap: 3rem;
}
.questions__success-hdr {
  margin: 1rem 0 1rem;
  font-size: 3.2rem;
  color: $black;
  font-weight: 600;
  letter-spacing: -0.04em;
  text-transform: capitalize;
}
.questions__success-text {
  color: $offBlack;
  font-weight: 500;
  font-size: 2rem;
}
.questions__success-btn {
  padding: 1.5rem 1.2rem;
  margin: 2rem;
  font-size: 2rem;
}
.questions__back-icon {
  width: 21px;
  margin-left: 2rem;
  height: inherit;
  fill: $base;
  cursor: pointer;
}
.questions__top-cont {
  width: 100%;
  @include flex();
  justify-content: flex-start;
  gap: 0.8rem;
  margin: 0.5rem 0 1.5rem;
}
.questions__loading {
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  color: $baseD;
  margin: 1rem 0;
}
.questions__alert-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 1rem 0.5rem;
  color: $baseD;
}
.questions__hdr {
  margin: 0 0 1rem;
  text-align: center;
}
.questions__questions-title {
  margin: 1rem 0 2rem;
  font-weight: 400;
  text-align: center;
}

.questions_ {
  &questionsContainer,
  &loginContainer {
    width: 85vw;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    margin: 1.5rem;
    padding: 1.5rem 2rem;
    background: $lGray;
    @include flexCol();
    max-width: 300px;
    border-radius: 8px;
  }
  &progressContainer {
    margin: 1rem 0;
  }
  &textInput {
  }
  &topContainer {
  }
  &questionsTitle {
  }
  &hdr {
    font-size: 2rem;
    width: 80%;
    margin: 0.2rem 0 0.5rem;
    overflow: visible;
    text-align: center;
  }
  &label {
    font-size: 1.5rem;
    margin: 0.7rem 0 0.5rem;
    text-transform: capitalize;
    text-align: center;
  }
  &checkbox {
    margin: 0.5rem 0 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid;
    width: 25px;
    height: 25px;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    overflow: visible;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.4);
    background: $offWhite;

    &::before {
      content: "\2713";
      position: absolute;
      font-size: 50px;
      line-height: 25px;
      transform: scale(0);
      transition: transform 0.3s ease; /* Add a transition for smooth animation */
    }

    &:checked::before {
      transform: scale(1);
      color: #03b539;
    }
  }
  &changeContainer {
  }
  &buttonChange {
  }
  &buttonSubmit {
    font-weight: 700;
    margin: 2rem 0.5rem 0;
    font-size: 1.2rem;
    background: $white;
    color: $baseD;
    width: 55%;
    @include flexCenR();
    border: 1px solid;
    padding: 0.5rem 0.7rem;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.4);
    &:hover {
      background: $base;
      color: $white;
      border: 1px solid $base;
    }
  }
  &success {
  }
  // Login page
  &loginContainer {
    @include flexCol();
  }
  &loginTitle {
    font-size: 2.5rem;
    text-align: center;
    margin: 1rem 0;
    color: $gray;
  }
  &alertMessage {
  }
  &loginHdr {
    font-size: 2rem;
    text-align: center;
    margin: 1rem 0 1.5rem;
  }
  &createAccount {
    margin: 1rem 1rem 0.5rem;
    cursor: pointer;
    transition: color 0.15s;
    width: 80%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    &:hover {
      color: $base;
    }
  }
  &btnContainer {
    margin: 0.5rem 0 0;
  }
  &buttonLogin {
    width: 82%;
    font-weight: 700;
    margin: 1rem;
    font-size: 1.2rem;
    background: $base;
    color: $white;
    @include flexCenR();
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0.7rem;
    border-radius: 3px;
    border: 1px solid $base;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: $white;
      color: $baseD;
      border: 1px solid;
    }
  }
  &loading {
  }
  &answersContainer {
    width: 100%;
    @include flexCenR();
  }
  &right {
    width: 100%;
    @include flexCol();
  }
  &left {
    width: 100%;
    @include flexCol();
  }
  @media (min-width: 480px) {
    &container {
      width: 300px;
    }
  }
  @media (min-width: 769px) {
    &left {
      width: 40%;
    }
    &container {
      @include flexCenR();
      width: 100%;
      max-width: 900px;
      align-items: flex-start;
      justify-content: center;
      margin: 2rem 0 0;
    }
    &right {
      width: 35%;
    }
    &buttonSubmit {
      margin: 2rem 0.5rem 0;
    }
  }
}
