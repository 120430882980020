@import './variables.scss';
#hdrOne{
    margin-left: 5%;
}
.compare_{
    &container{
        width: 100%;
        height: 100%;
        @include flexCol();
        padding: 0.7rem;
        gap: 0.8rem;
    }
    &hdr{
        font-size: 1.2rem;
        color: $base;
    }
    &grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        height: 100%;
        gap: 0.5rem;
    }
    &gridColumn{
        background: $offWhite;
        // height: 400px;
        border: 1px solid $grayBorder;
        border-radius: 8px;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
        margin: 0 0 1rem;
        &:first-child{
            border: 1px solid transparent;
            background: transparent;
            box-shadow: none;
            margin: 0 0 1rem;
        }
    }
    &topContainer{
        width: 100%;
        height: 100px;
        overflow: hidden;
        @include flexCenC();
        margin: 0 0 1rem;
    }
    &logoContainer{
        width: 100%;
        height: 90%;
        @include flexCenR();
        overflow: hidden;
    }
    &title{
        font-size: 1rem;
        text-align: center;
        font-weight: 700;
        color: $gray;
        height: 30%;
        width: 95%;
        @include flexCenR();
    }
    &bottomContainer{
        display: grid;
        grid-template-rows: repeat(7, 1fr);
        align-items: center;
        width: 100%;
        margin: 0.8rem 0 0;
    }
    &scoreContainer{
        border-top: 1px solid $grayBorder;
        @include flexCenC();
        width: 100%;
        height: 2.5rem;
    }
    &score{
        font-size: 1.5rem;
        padding: 0.3rem 0;
        color: $gray;
    }
    &titleContainer{
        height: 2.5rem;
        @include flexCenC();
        padding: 0 0 0 0.2rem;
    }
    &blockTitle{
        font-size: 1rem;
        text-align: left;
        width: 100%;
        margin-left: 0.3rem;
        font-weight: 700;
        color: $base;
    }
    @media(min-width:480px){
        &blockTitle{
            margin-left: 1.5rem;
        }
        &title{
            font-size: 1.1rem;
        }
        &logoContainer{
            width: 85%;
            height: 70%;
        }
        &topContainer{
            height: 75px;
        }
    
    }
    @media(min-width:769px){
        &titleContainer{
            height: 3.8rem;
        }
        &blockTitle{
            font-size: 1.4rem;
            margin-left: 1.5rem;
        }
        &score{
            font-size: 1.7rem;
        }
        &title{
            font-size: 1.5rem;
        }
        &scoreContainer{
            height: 3.8rem;
        }
        &topContainer{
            height: 110px;
        }
        &logoContainer{
            width: 80%;
            height: 55%;
        }
    }
}

#hl{
    transform: scale(0.65);
}