$base: #ff06d7;
$baseL: #ff78e1;
$baseD: #ed00be;
$gray: #3f3f3f;
$lGray: #dfdfdf;
$grayBorder: rgba(63, 63, 63, 0.5);
$highlight: #c5ecf9;
$black: #101010;
$white: #fff;
$offWhite: #f7fcff;
$offBlack: #4a4444;
$backgroundPink: rgba(249, 211, 247, 0.5);

$shadow: 0 0 2px $offBlack;
$textShadow: 0 0 0.2px $base;

@mixin flex($dir: row) {
  display: flex;
  align-items: center;
  flex-direction: $dir;
}
@mixin flexCol {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@mixin flexCenC {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@mixin flexCenR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@mixin flexC($dir: row) {
  display: flex;
  flex-direction: $dir;
  align-items: center;
  justify-content: center;
}

.separator {
  width: 100vw;
  height: 0.5px;
  background: $offBlack;
  margin: 0;
  // @media(min-width:769px){
  //     width: 110%;
  // }
  // @media(min-width:1024px){
  //     width: 95%;
  // }
  // @media(min-width:1200px){
  //     width: 90%;
  // }
  // @media(min-width:1350px){
  //     width: 93%;
  // }
}
.fadeEffect {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  //    transform: translateY(0);
}
.fadeEffect.hidden {
  opacity: 0;
  // transform: translateY(100%);
}
/* Add these styles to your CSS */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid $base;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  @include flexCenR();
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
