@import "./variables.scss";

.input__container {
  @include flexCol();
  margin: 0 0 0.5rem;
  overflow: visible;
}
.input__label {
  font-size: 1.5rem;
  margin: 0.7rem 0 0.5rem;
  text-transform: capitalize;
  text-align: center;
}
.input__checkbox {
  margin: 0.5rem 0 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid;
  width: 25px;
  height: 25px;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  overflow: visible;
  cursor: pointer;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.4);
  background: $offWhite;
  &::before {
    content: "\2713";
    position: absolute;
    font-size: 50px;
    line-height: 25px;
    transform: scale(0);
    transition: transform 0.3s ease; /* Add a transition for smooth animation */
  }
  &:checked::before {
    transform: scale(1);
    color: #03b539;
  }
}
.input__textbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-bottom: 1px solid $base;
  border-radius: 3px;
  padding: 0.2rem;
  margin: 0 0 1rem;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);

  &:focus {
    border-bottom: 1px solid;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
  }
}
