@import "./variables.scss";

.fullReport_ {
  &container {
    padding: 0.8rem;
    width: 100%;
    @include flexCol();
    gap: 0.7rem;
  }
  &contentContainer {
    border: 1px solid $grayBorder;
    background: $offWhite;
    width: 90%;
    height: 150px;
    border-radius: 8px;
    margin: 0 1rem 1rem;
    padding: 0.5rem;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
  }
  &text {
    font-size: 1.1rem;
  }
  @media (min-width: 769px) {
    &text {
      font-size: 1.3rem;
    }
  }
}
