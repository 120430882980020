@import './variables.scss';

.progressBar_{
    &container{
        width: 80vw;
        @include flex;
        max-width: 250px;
        border-radius: 10px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
        margin: 0.8rem;
    }
    &section{
        width: 100%;
        height: 15px;
        border: 1px solid rgba(15, 224, 78, 1);
        // border-radius: 2px;
        cursor: pointer;
        border-left: 0;
        &:hover{
            border: 1px solid #03b539;
        }
        &:first-child{
            border-radius: 10px 0 0 10px;
            border: 1px solid rgba(15, 224, 78, 1);
        }
        &:last-child{
            border-radius: 0 10px 10px 0;
        }
    }
    @media (min-width:480px){
        &container{
            width: 250px;
        }
    }
    @media (min-width:769px){
        &container{
            width: 230px;
        }
    }
}
// 
@keyframes loading {
    0%{
        background: rgba(15, 224, 78, 0.1);
        box-shadow: 0 0 5px 5px rgba(15, 224, 78, 0.5);
    }
    20%{
        background: rgba(15, 224, 78, 0.2)
    }
    30%{
        background: rgba(15, 224, 78, 0.3)
    }
    40%{
        background: rgba(15, 224, 78, 0.4)
    }
    50%{
        background: rgba(15, 224, 78, 0.5)
    }
    60%{
        background: rgba(15, 224, 78, 0.6)
    }
    70%{
        background: rgba(15, 224, 78, 0.7)
    }
    80%{
        background: rgba(15, 224, 78, 0.8)
    }
    90%{
        background: rgba(15, 224, 78, 0.9)
    }
    100%{
        background: rgba(15, 224, 78, 1);
    }

}