@import "./variables.scss";

.home_container {
  @include flexCol();
}
.layout_ {
  &pageContainer {
    @include flexCol();
    background-color: $white;
  }
  &container {
    padding: 20px 20px 0;
    @include flexCol();
  }
}
.landingPage_ {
  &container {
    width: 100vw;
  }
  &heroLeft {
    @include flexCol();
    align-items: flex-start;
    width: 100%;
    z-index: 90;
    height: fit-content;
    border-radius: 3px;
    animation: opacity 1s 1 forwards;
  }
  &hdr {
    font-size: 3.5rem;
    margin: 1rem 0 2rem 1rem;
    font-weight: 600;
    text-shadow: 0 0 7px $white;
    width: 80%;
    text-align: left;
    animation: slideTop 0.5s ease-out 1;
    font-family: "Anton", sans-serif;
  }
  &listContainer {
    margin: 0.5rem 0 1.5rem;
    width: 76%;
    overflow: visible;
    display: none;
  }
  &listItem {
    text-align: left;
    font-size: 1.4rem;
    &::before {
      content: "✓   ";
      animation: green 1.8s ease-in-out 0.5s 1 forwards;
    }
  }
  &subtext {
    font-size: 1rem;
    font-weight: 400;
    width: 70%;
    margin: 0 0 1rem;
    text-align: center;
  }
  &captureContainer {
    width: 100%;
    @include flex();
    justify-content: flex-start;
    margin: 0 0 0 1rem;
    overflow: hidden;
  }
  &captureWrapper {
    overflow: hidden;
    @include flex();
    justify-content: flex-start;
    width: 60%;
    animation: slideBottom 0.5s ease-in-out 1 forwards;
  }
  &heroContainer {
    @include flexCol();
    align-items: flex-start;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 2rem;
  }
}
@media (min-width: 480px) {
  .layout_ {
    &container {
      padding: 0px 0 0;
    }
  }
  .landingPage_ {
    &heroContainer {
      justify-content: flex-start;
    }
    &heroLeft {
      margin: 3rem 0 0 5%;
      width: 90%;
    }
    &hdr {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
    &listContainer {
      margin: 2rem 1rem 1rem 25%;
      width: 100%;
    }
    &listItem {
      list-style-type: circle;
      font-size: 1.6rem;
      font-weight: 600;
      text-shadow: 0 0 5px $white;
      &:first-child {
        transform: translateX(-100%);
        animation: slideLeft 0.6s ease-in 1 forwards;
      }
      &:nth-child(2) {
        transform: translateX(-100%);
        animation: slideLeft 0.6s ease-in 0.2s 1 forwards;
      }
      &:nth-child(3) {
        transform: translateX(-100%);
        animation: slideLeft 0.6s ease-in 0.4s 1 forwards;
      }
    }
    &listItem {
    }
    &captureContainer {
      width: 100%;
      max-width: 350px;
      margin-bottom: 1.5rem;
    }
    &captureWrapper {
      width: 80%;
      overflow: hidden;
    }
  }
}
@media (min-width: 769px) {
  .layout_ {
    &container {
      padding: 0;
    }
  }
  .landingPage_ {
    &heroContainer::before {
      transform: rotate(180deg) scale(1.1);
    }
    &heroLeft {
      width: 70%;
      margin-left: 7%;
    }
    &hdr {
      font-size: 5.5rem;
    }
    // &listContainer{
    //     margin: 1rem 0 2rem 11rem;
    //     display: none;
    // }
    // &listItem{
    //     font-size: 1.8rem;
    // }
    &captureWrapper {
      width: 100%;
    }
    &captureContainer {
      max-width: 450px;
    }
  }
}
@media (min-width: 1024px) {
  .landingPage_ {
    &heroLeft {
      // margin: 65px 45px 40px 15%;
    }
    &hdr {
      margin: 2.8rem 0 5rem 2%;
      font-size: 7rem;
    }
    // &listContainer{
    //     margin: 1rem 0 3.5rem 25%;
    // }
    // &listItem{
    //     font-size: 2.2rem;
    // }
  }
}
@media (min-width: 1200px) {
  .landingPage_ {
    &heroLeft {
      width: 60%;
    }
    &hdr {
      margin: 2.8rem 0 5rem 2%;
      font-size: 8.5rem;
    }
    &captureWrapper {
      width: 100%;
    }
    &captureContainer {
      max-width: 500px;
    }
    // &listContainer{
    //     margin: 1rem 0 3.5rem 25%;
    // }
    // &listItem{
    //     font-size: 2.2rem;
    // }
  }
}
@media (min-width: 1350px) {
  .landingPage_ {
    &heroContainer::before {
      transform: scale(1) rotate(180deg);
      background-position: -400px 100px;
    }
    &heroLeft {
      margin: 3.5rem 0 0 10%;
    }
    &hdr {
      font-size: 8rem;
      margin-bottom: 2.5rem;
    }
    &captureContainer {
      margin: 1.5rem 0;
      max-width: 650px;
    }
    // &listContainer{
    //     margin: 1rem 0 2rem 25%;
    // }
    // &listItem{
    //     margin-bottom: 2rem;
    //     color: $offBlack;
    // }
    // &captureContainer{
    //     width: 75%;
    //     margin: 1rem 0 5rem 0;
    //     justify-content: flex-start;
    // }
  }
}
