* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #ed00be #ff78e1;
}
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #ff78e1;
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff06d7;
  border-radius: 50px;
}

*::-webkit-scrollbar-button:start:decrement {
  background-image: url("./svg/up.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

*::-webkit-scrollbar-button:end:increment {
  background-image: url("./svg/down.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

html {
  font-size: 62.5%;
}
body {
  font-family: "Lexend Deca", Arial, Helvetica, sans-serif;
  /* font-family: 'Questrial', Arial, Helvetica, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -font-smoothing: antialiased;
  margin: 0;
  /* color: #c5ecf9 */
}
