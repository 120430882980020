@import "./styles/variables.scss";

/* Flex */

.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flex--col {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.flex--cen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

/* Clickable */

.clickable {
  transition: transform 0.15 ease-in-out;
  cursor: pointer;
}
.clickable:hover {
  transform: scale(1.05);
}
.clickable:active {
  transform: scale(1.02);
}

/* Buttons */

.btn,
.btn--primary {
  border: none;
  background: none;
  cursor: pointer;
}
.btn--primary {
  border-radius: 7.5px;
  font-weight: 600;
  background: $base;
  color: $offWhite;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

// Text

.highlight {
  color: $base;
}
.underline {
  text-decoration: underline;
  text-decoration-color: $baseL;
  text-underline-offset: 0.2rem;
  text-decoration-thickness: 2.2px;
}
.hdr--md {
}
