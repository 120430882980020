@import "./variables.scss";
.banner__container {
  @include flexC();
  margin: 3.5rem 0 1rem;
  max-width: 100%;
  overflow: hidden;
  & > * {
    overflow: hidden;
  }
}
.banner__marquee {
  max-width: 90vw;
}
.banner__icon-cont {
  height: 125px;
  @include flexC();
  margin: 0 0.5rem;
  cursor: pointer;
}
#near {
  transform: scale(0.8);
}
#ripple {
  transform: scale(0.9);
}
#hyperledger,
#avalanche {
  transform: scale(1.3);
  margin: 0 4rem;
}
#ethereum {
  margin-right: 1rem;
  margin-left: -2rem;
}
#solana {
  margin-right: 1rem;
}

@media (min-width: 480px) {
  .banner__icon-cont {
    transform: scale(1.1);
  }
}
@media (min-width: 769px) {
  .banner__container {
    margin: 5rem 0 3rem;
  }
  .banner__icon-cont {
    transform: scale(1.25);
    margin: 0 1rem;
  }
}
@media (min-width: 1024px) {
  .banner__container {
    margin-top: 7.5rem;
  }
  .banner__icon-cont {
    transform: scale(1.35);
    margin: 0 3rem;
  }
  #ethereum {
    margin-left: 2rem;
    margin-right: 4rem;
  }
  #solana {
    margin-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .banner__container {
    margin-bottom: 0rem;
    margin-top: 5.5rem;
  }
  .banner__icon-cont {
    transform: scale(1.45);
    margin: 0 4rem;
  }
  #ethereum {
    margin-left: 2rem;
    margin-right: 5rem;
  }
  #solana {
    margin-right: 4rem;
  }
}
