@import "./variables.scss";
.footer__container {
  @include flex($dir: column);
  padding: 1rem 2rem 2rem;
  width: 90%;
  margin: 1rem;
  flex: 1;
  justify-content: flex-end;
}
.footer__copyright {
  font-size: 1.2rem;
  font-weight: 400;
  color: $black;
  letter-spacing: -0.04em;
}
.footer__links-cont {
  border-top: 1px solid $baseL;
  width: 100%;
  padding-top: 1.5rem;
  margin: 0.5rem 2rem 1.2rem;
  @include flexC();
  gap: 3rem;
}
.footer__link-cont {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  padding: 1rem;
  fill: $base;
}
.footer__link {
  width: 25px;
  height: 25px;
}

@media (min-width: 769px) {
  .footer__container {
    margin-top: 3rem;
  }
  .footer__links-cont {
    margin: 2rem;
  }
  .footer__link {
    width: 35px;
    height: 35px;
  }
  .footer__copyright {
    font-size: 1.5rem;
  }
}
