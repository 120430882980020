@import './variables.scss';

.result_{
    &container{
        width: 100%;
        @include flexCol();
    }
    &result, &manage{
        @include flexCol();
    }
    &manage{
        margin: 0 0 1.5rem;
    }
    &hdr{
        text-transform: capitalize;
        margin: 0.2rem 0 1rem;
    }
    &binContainer{
        width: 50px;
        fill: red;
        height: inherit;
        overflow: hidden;
        margin: 1rem;
        border-radius: 10px;
        @include flexCenR();
        padding: 0.9rem;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
        &:hover{
            box-shadow: inset 0 0px 2px 0 rgba(0, 0, 0, 0.4);
        }
    }
}
.resultsComp_{
    &container{
        background: rgba(223, 223, 223, 0.6);
        width: 95%;
        @include flexCol();
        padding: 0.8rem;
        border-radius: 12px;
        margin: 1rem 0 2rem;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    }
    &hdr{
        font-size: 2.2rem;
        color: $gray;
        text-transform: capitalize;
        font-weight: 500;
    }
    @media(min-width:480px){
        &container{
            width: 90%;
        }
    }
    @media(min-width:769px){
        &container{
            max-width: 1000px;
        }
        &hdr{
            font-size: 2.8rem;
        }
    }
}