@import "./variables.scss";

.apps_ {
  &layoutContainer {
    padding: 7rem 0 0;
    @include flexCol();
  }
  &sectionContainer {
    @include flexCol();
  }
  &container {
    @include flexCol();
    scrollbar-width: thin;
    scrollbar-color: $baseD $baseL;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: $baseL;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $baseD;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-button:start:decrement {
      // background-image: url("../svg/up.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::-webkit-scrollbar-button:end:increment {
      // background-image: url("../svg/down.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &hdr {
    font-size: 2.3rem;
    margin: 1rem 0 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $baseD;
    border: 1px solid;
    padding: 0.4rem 0.8rem;
    background: $offWhite;
    letter-spacing: 0.15rem;
    border-radius: 3px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
  }
  &text {
    font-size: 1.5rem;
    color: $offBlack;
    margin: 1rem 0;
  }
  &create {
    border: none;
    background: $base;
    color: $offWhite;
    padding: 0.5rem 0.8rem;
    border-radius: 3px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 1.5rem 0 4rem;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: $base;
      background: transparent;
      transform: scale(1.2);
      box-shadow: none;
    }
  }
  &matchesContainer {
    min-width: 55vw;
    margin: 1rem 1rem 4rem;
    @include flexCol();
    padding: 2rem;
    gap: 1.5rem;
    box-shadow: 0 4px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background: rgba(223, 223, 223, 0.6);
  }
  &matchContainer {
    width: 180px;
    height: 100px;
    // margin: 0.5rem;
    border-radius: 12px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.5);
    }
  }
  &logout {
    color: #525252;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 3rem 0 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: rgba(255, 0, 0, 0.7);
    }
  }
  &delete {
    color: red;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 2rem 0;
    font-size: 1.3rem;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      color: rgba(255, 0, 0, 0.7);
    }
  }
  &binContainer {
    @include flexC($dir: column);
  }
  &bin {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 480px) {
    &layoutContainer {
      padding-top: 9rem;
    }
    &matchContainer {
      width: 200px;
      height: 130px;
    }
    &matchesContainer {
      gap: 2rem;
    }
    &hdr {
      font-size: 2.5rem;
      margin: 0 0 1.5rem;
    }
  }
  @media (min-width: 769px) {
    &matchesContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1024px) {
    &matchesContainer {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1200px) {
    &matchesContainer {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
