@import "./variables.scss";

.mainCard__container {
  @include flex($dir: column);
  width: 90vw;
  max-width: 480px;
  background: $base;
  justify-content: space-around;
  padding: 3rem 2.5rem;
  gap: 1.5rem;
  border-radius: 7.5px;
  margin: 0 0 2rem;
}
.mainCard__hdr {
  color: $white;
  font-size: 3.2rem;
  font-weight: 600;
  text-align: left;
  width: 100%;
  letter-spacing: -0.04em;
}
.mainCard__text {
  color: $white;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.6;
  font-weight: 400;
}
@media (min-width: 480px) {
  .mainCard__container {
    gap: 2.5rem;
    padding: 3rem 4rem;
  }
  .mainCard__text {
    font-size: 1.75rem;
  }
}
@media (min-width: 769px) {
  .mainCard__container {
    max-width: 550px;
    padding: 5rem;
  }
  .mainCard__text {
    font-size: 1.75rem;
    font-weight: 500;
    color: $offWhite;
  }
}
@media (min-width: 1024px) {
  .mainCard__container {
    width: 100%;
    margin: 0 3rem 0 0;
    padding: 3rem 0 0 2rem;
    background: none;
  }
  .mainCard__hdr {
    font-size: 4rem;
    color: $base;
    font-weight: 400;
  }
  .mainCard__text {
    font-size: 2.3rem;
    color: $baseD;
    font-weight: 400;
  }
}
@media (min-width: 1200px) {
  .mainCard__container {
    width: 100%;
    margin: 0;
  }
  .mainCard__hdr,
  .mainCard__text {
    margin-bottom: 1rem;
  }
}
