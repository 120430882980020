@import "./variables.scss";

.userQuestions__container {
  @include flex($dir: column);
  margin: 0 0 2rem;
  width: 100%;
}
.userQuestions__questions-cont {
  @include flex($dir: column);
  overflow: hidden;
}
.userQuestions__question-title {
  text-align: center;
  font-weight: 400;
  margin: 1rem 0;
}
.userQuestions__subtext {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0.5rem 0;
  text-align: center;
}
.userQuestions__login {
  margin: 1.5rem 0 1rem;
  font-weight: 600;
  line-height: 2rem;
  font-size: 1.3rem;
  text-align: center;
  color: $offBlack;
  transition: all 0.2s;
  cursor: pointer;
  span {
    color: $baseD;
  }
  &:hover {
    color: $baseD;
  }
}
.userQuestions__auth-message {
  font-weight: 500;
  color: $offBlack;
  width: 100%;
  text-align: center;
  margin: 1rem 0 2rem;
}
.userQuestions__logout {
  font-size: 1.2rem;
  text-align: center;
  width: 70%;
  font-weight: 500;
  color: $offBlack;
  margin: 0.75rem;
  &:hover {
    color: $baseD;
  }
}
@media (min-width: 769px) {
}
