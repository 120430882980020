@import "./variables.scss";

.projectQuestions__container {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  @include flex($dir: column);
}
.projectQuestions__questions-cont {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  @include flex($dir: column);
}

.projectQuestions_ {
  &container {
  }
  &inputContainer {
    @include flexCol();
    overflow: visible;
  }
  &questionsContainer {
    padding: 1rem 2rem 1rem;
    @include flexCol();
    justify-content: flex-start;
    width: 90%;
  }
}
