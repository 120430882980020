@import "./variables.scss";

.message__container {
  text-align: center;
  padding: 1rem;
  max-width: 600px;
}
.message__hdr {
  margin: 0 0 2.5rem;
  text-transform: capitalize;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.04em;
}
.message__text {
  font-size: 2.6rem;
  line-height: 1.6;
  letter-spacing: 1px;
  font-weight: 400;
}
.message__gfx-cont {
  width: 80vw;
  margin: 3rem 0;
  height: 400px;
  position: relative;
}
.message__wrapper {
  width: 100%;
}
.message__wrapper li {
  display: inline-block;
  list-style: none;
  margin-right: 1rem;
}

// .message__gfx-cont::after {
//   content: ""; /* Required for pseudo-elements */
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: $base url("../svg/message-graphic2.png") center/cover no-repeat;
//   background-blend-mode: luminosity;
// }

@media (min-width: 769px) {
  .message__container {
    padding: 3rem 1rem;
    max-width: 600px;
  }
  .message__hdr {
    font-size: 3.5rem;
    margin: 1.5rem 0 3rem;
  }
  .message__text {
    font-size: 3rem;
  }
}
@media (min-width: 1024px) {
  .message__container {
    max-width: 1000px;
  }
  .message__hdr {
    font-size: 5.5rem;
    margin: 0 0 4rem;
  }
  .message__text {
    font-size: 4rem;
  }
  .message__wrapper li {
    margin-right: 1.5rem;
  }
}
