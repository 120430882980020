@import "./variables.scss";

.infoCards__container,
.infocards__cards-cont,
.mainCard__container,
.infoCards__card-cont {
  @include flex($dir: column);
}
.infoCards__card-cont {
  position: relative;
}
.infoCards__container {
  margin: 6rem 0 2rem;
}

.infoCards__icons-cont {
  display: none;
  // position: absolute;
}

@media (min-width: 1024px) {
  .infoCards__container {
    margin-top: 6rem;
    width: 90vw;
    max-width: 1300px;
  }
  .infoCards__card-cont,
  .infocards__cards-cont {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .infoCards__card-cont {
    margin-bottom: 3rem;
    justify-content: flex-end;
  }
  .infocards__cards-cont {
    align-items: flex-end;
  }
  .infoCards__icons-cont {
    @include flex();
    width: 90%;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  .infoCards__icon-cont {
    @include flexC();
    fill: $white;
    margin-right: 2rem;
    padding: 0.7rem;
    background: $base;
    border-radius: 50px;
    &:first-child {
      transform: rotate(180deg);
      &:hover {
        transform: scale(1.05) rotate(180deg);
      }
      &:active {
        transform: scale(1.02) rotate(180deg);
      }
    }
  }
  .infoCards__icon {
    width: 30px;
    height: 30px;
    padding-left: 0.6rem;
  }
}
@media (min-width: 1200px) {
  .infoCards__card-cont {
    margin-right: 4%;
  }
  .infoCards__icon {
    width: 35px;
    height: 35px;
  }
  .infoCards__icons-cont {
    margin-top: -1.5rem;
  }
}
