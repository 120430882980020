@import "./variables.scss";

.login__container {
  @include flex($dir: column);
  width: 90%;
}
.login__hdr {
  font-weight: 400;
  font-size: 2rem;
  margin: 2rem 1rem;
  text-align: center;
  color: $offBlack;
}
.login__component-cont {
  @include flex($dir: column);
}
.login__or-cont {
  @include flexC();
  width: 75%;
  margin: 1rem;
}
.login__or-line {
  height: 1.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.login__or {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 1rem;
  color: $offBlack;
}
.login__google-cont {
  @include flexC();
  margin: 1rem;
}
.login__alert-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 1rem 0.5rem;
  color: $baseD;
}
.login__label {
  font-size: 1.5rem;
  margin: 0.7rem 0 0.5rem;
  text-transform: capitalize;
  text-align: center;
}
.login__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-bottom: 1px solid $base;
  border-radius: 3px;
  padding: 0.2rem;
  margin: 0 0 1rem;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);

  &:focus {
    border-bottom: 1px solid;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
  }
}

// Loading Status
.loadingStatus__hdr {
  margin: 2rem 1rem 0.5rem;
  cursor: pointer;
  transition: color 0.15s;
  width: 80%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: $baseD;
  span {
    color: $offBlack;
  }
  &:hover {
    color: $base;
  }
}
.loadingStatus__text {
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  color: $baseD;
  margin: 1rem 0;
}

// Submit Button
.submitBtn__container {
  margin: 0.5rem 0 0;
}
.submitbtn__btn {
  width: 82%;
  margin: 1rem;
  font-size: 1.2rem;
  @include flexCenR();
  padding: 0.5rem 0.7rem;
  transition: all 0.2s;
}
.submitBtn__success {
  font-size: 1.8rem;
  padding: 0.5rem 0.8rem;
}
