@import './variables.scss';

.resultComp_{
    &container{
        border-radius: 12px;
        @include flexCol();
        margin: 0.8rem 0;
        padding: 0.8rem 0 0.3rem;
        width: 100%;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        background: $lGray;
    }
    &hdr{
        font-size: 1.7rem;
        color: $base;
        margin: 0.5rem 0;
    }
    @media(min-width:480px){
        &container{
            width: 90%;
            max-width: 700px;
        }
    }
}

