@import "./variables.scss";

.getStarted__container {
  @include flex($dir: column);
  gap: 3rem;
  margin: 4rem 2rem 2rem;
}
.getStarted__hdr {
  font-size: 2.7rem;
  text-align: center;
  // text-transform: capitalize;
  // margin: 0 0 1rem;
  font-weight: 400;
  letter-spacing: -0.04rem;
}
.getStarted__btn-cont {
  @include flexC();
  width: 95%;
  max-width: 300px;
}
.getStarted__btn {
  padding: 2rem 3rem;
  width: 80%;
  font-size: 1.5rem;
}
@media (min-width: 480px) {
  .getStarted__container {
  }
  .getStarted__hdr {
    font-size: 2.9rem;
    width: 100%;
    line-height: 1.3;
  }
}
@media (min-width: 1024px) {
  .getStarted__hdr {
    text-align: left;
    font-weight: 300;
    margin-right: 10rem;
    width: fit-content;
    font-size: 3rem;
  }
  .getStarted__container {
    margin: 10rem 2rem 4rem;
    flex-direction: row;
    justify-content: center;
    width: 95%;
    max-width: 1200px;
  }
  .getStarted__btn-cont {
    padding: 0 2rem;
  }
}
@media (min-width: 1200px) {
  .getStarted__container {
    margin: 20rem 2rem 4rem;
  }
}
